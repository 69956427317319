import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { setShowModal } from "../redux/dataSlice";
import FirstPageContent from "./FirstPageContent";
import Header from "./Header";
import useIsMobile from "../hooks/useIsMobile";
import bgMin from "../images/bgMin.svg";
import playIcon from "../images/play.svg";

import { openDoc } from "./Header";
import { openTalk } from "./FirstPageContent";

function FirstPage() {
  const { isMobile } = useIsMobile();
  const { showModal } = useSelector((state) => state.data);

  const dispatch = useDispatch();

  const showVideoModal = () => {
    dispatch(setShowModal(true));
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };
  const list = [
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "green",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(164, 137, 243, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(243, 194, 8, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(249, 166, 225, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(55, 54, 220, 1)",
    },
  ];
  let newList = [];
  for (let i = 0; i < 50; i++) {
    newList = newList.concat(list);
  }
  return (
    <div className="firstPage">
      <Header />
      <div className="firstPage_bg">
        {!isMobile && (
          <div>
            <div className="bodyImgMax1440">
              <div className="bgMaxLeft">
                {/* <img src={bgMaxLeft} alt="" /> */}
              </div>
              <div className="bgMaxRight">
                {/* <img src={bgMaxRight} alt="" /> */}
              </div>
            </div>
            <div className="bodyImgMin1440">
              <div className="bgMinLeft">
                {/* <img src={bgMinLeft} alt="" /> */}
              </div>
              <div className="bgMinRight">
                {/* <img src={bgMinRight} alt="" /> */}
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div>
            <img src={bgMin} className="bodyImg" alt="" />
            <div className="mobileFP_textArea">
              <div className="mobileFP_text1">
                <div>An Efficient,Verifiable</div>
                <div>Off-Chain Computation Engine</div>
                <div>Powered by</div>
                <div>Zero-Knowledge Proofs</div>
              </div>
              <div className="mobileFP_text2">
                Enable Web3 applications to offload data-intensive, costly
                computations from on-chain environments to a drastically
                lower-cost off-chain engine, and scale seamlessly while
                preserving the security of L1 trust assumptions.
              </div>
              <div className="buttons" key="buttons">
                {/* <div
                  className="whitePaperBtn"
                  onClick={() => {
                    openDemo();
                  }}
                >
                  Try demos
                </div> */}
                <div
                  className="demoBtn"
                  onClick={() => {
                    openDoc();
                  }}
                >
                  Let’s build
                </div>
                <div
                  className="demoBtn"
                  onClick={() => {
                    openTalk();
                  }}
                >
                  Talk to a builder
                </div>
                <div
                  className="demoBtnVideo"
                  onClick={() => {
                    showVideoModal();
                  }}
                >
                  <img src={playIcon} className="palyVideo" alt="paly video" />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isMobile && <FirstPageContent />}
      </div>

      <div className="fp_bottom">
        <div className="light">
          {newList.map((item, index) => {
            return (
              <div
                key={index}
                className="lightItem"
                style={{ color: item.colorType }}
              >
                {item.text}
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        title={null}
        footer={null}
        maskClosable
        open={showModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        centered
        className="videoModal"
        width={isMobile ? "90%" : "60%"}
        destroyOnClose
      >
        <div className="videoOut">
          <video
            className="videoContent"
            controls
            autoPlay
            preload="auto"
            playsInline
            // controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
            // disablePictureInPicture
          >
            <source
              src="https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/video/introduction.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </Modal>
    </div>
  );
}

export default FirstPage;
