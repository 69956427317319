/* eslint-disable no-loop-func */
import React from "react";
import QueueAnim from "rc-queue-anim";
import { useDispatch } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";
import { openDoc } from "./Header";
import { setShowModal } from "../redux/dataSlice";
import playIcon from "../images/play.svg";

export const openTalk = () => {
  window.open("https://form.typeform.com/to/lduiliob", "_blank");
};
export const openDemo = () => {
  window.open("https://demo.brevis.network/", "_blank");
};

const FirstPageContent = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const showVideoModal = () => {
    dispatch(setShowModal(true));
  };

  // useEffect(() => {
  //   if (isModalOpen && document) {
  //     const videos = document.querySelectorAll("video");
  //     videos.forEach((video) => {
  //       video.addEventListener("click", (event) => {
  //         event.preventDefault();
  //       });
  //       video.addEventListener("dblclick", () => {
  //         if (!document.fullscreenElement) {
  //           if (video.requestFullscreen) {
  //             video.requestFullscreen();
  //           } else if (video.mozRequestFullScreen) {
  //             video.mozRequestFullScreen();
  //           } else if (video.webkitRequestFullscreen) {
  //             video.webkitRequestFullscreen();
  //           }
  //         } else {
  //           if (document.exitFullscreen) {
  //             document.exitFullscreen();
  //           } else if (document.mozCancelFullScreen) {
  //             document.mozCancelFullScreen();
  //           } else if (document.webkitExitFullscreen) {
  //             document.webkitExitFullscreen();
  //           }
  //         }
  //       });
  //     });
  //   }
  // }, [isModalOpen]);
  return (
    <div className="firstPageContent">
      <div>
        {!isMobile && (
          <div className="firstPageArea">
            <QueueAnim
              type="bottom"
              duration={1500}
              delay={500}
              key="firstPageText2"
            >
              <div className="firstPageText" key="firstPageText">
                <div>An Efficient,Verifiable Off-Chain</div>
                <div>Computation Engine Powered by</div>
                <div>Zero-Knowledge Proofs</div>
              </div>
            </QueueAnim>
            <div className="firstPageText2">
              <QueueAnim
                type="bottom"
                duration={1500}
                delay={400}
                key="Empowers2"
              >
                <div key="Empowers">
                  Enable Web3 applications to offload data-intensive, costly
                  computations from on-chain environments to a drastically
                  lower-cost off-chain engine, and scale seamlessly while
                  preserving the security of L1 trust assumptions.
                </div>
              </QueueAnim>
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <QueueAnim type="bottom" duration={2000} delay={500} appear={!isMobile}>
          <div className="buttons" key="buttons">
            {/* <div
              className="whitePaperBtn"
              onClick={() => {
                openDemo();
              }}
            >
              Try demos
            </div> */}
            <div
              className="demoBtn"
              onClick={() => {
                openDoc();
              }}
            >
              Let’s build
            </div>
            <div
              className="demoBtn"
              onClick={() => {
                openTalk();
              }}
            >
              Talk to a builder
            </div>
            <div
              className="demoBtnVideo"
              onClick={() => {
                showVideoModal();
              }}
            >
              <img src={playIcon} className="palyVideo" alt="paly video" />
            </div>
          </div>
        </QueueAnim>
      )}
    </div>
  );
};
export default FirstPageContent;
// //全屏按钮
// video::-webkit-media-controls-fullscreen-button {
//   display: none;
// }
// /* //播放按钮 */
// video::-webkit-media-controls-play-button {
//   display: none;
// }
// /* //进度条 */
// /* video::-webkit-media-controls-timeline {
//   display: none;
// } */
// /* //观看的当前时间 */
// video::-webkit-media-controls-current-time-display{
//   display: none;
// }
// /* //剩余时间 */
// video::-webkit-media-controls-time-remaining-display {
//   display: none;
// }
// /* //音量按钮 */
// video::-webkit-media-controls-mute-button {
//   display: none;
// }
// video::-webkit-media-controls-toggle-closed-captions-button {
//   display: none;
// }
// /* //音量的控制条 */
// video::-webkit-media-controls-volume-slider {
//   display: none;
// }
// /* //所有控件 */
//  video::-webkit-media-controls-enclosure{
//   display: none;
// }
